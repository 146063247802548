import React, { useState, useEffect } from 'react';


const ImageBanner = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        "https://res.cloudinary.com/dy2odrrzx/image/upload/v1735880752/IMG_0086_o2vqju.webp",
        "https://res.cloudinary.com/dy2odrrzx/image/upload/v1735877371/IMG_0082_b9nanw.webp",
        "https://res.cloudinary.com/dy2odrrzx/image/upload/v1735877371/IMG_0083_qr9yxd.webp",
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 2000); // Change image every 2 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [images.length]); // Add images.length to the dependency array

    return (
        <div className="banner">
            <img
                src={images[currentIndex]}
                alt={`Banner ${currentIndex + 1}`}  // Remove the redundant "image" or "photo" in alt text
                className="banner-image"
            />
        </div>
    );
};

export default ImageBanner;
