import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Pagination = ({ page, pages, keyword = "" }) => {
  const location = useLocation(); // Hook to track the current location (page URL)

  // Generate pagination links
  const getPageLink = (pageNum) => {
    if (keyword && keyword.trim() !== "") {
      return `/search/${keyword}/page/${pageNum}`;
    }
    return `/page/${pageNum}`;
  };

  // Calculate pagination range
  const getPaginationRange = () => {
    let start = Math.max(1, page - 1);
    let end = Math.min(pages, page + 1);

    if (pages > 3) {
      if (page === 1) {
        start = 1;
        end = 3;
      } else if (page === pages) {
        start = pages - 2;
        end = pages;
      }
    }

    return { start, end };
  };

  const { start, end } = getPaginationRange();

  // Scroll to top on page change (location change)
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top
  }, [location]); // This effect runs whenever the location (URL) changes

  return (
    pages > 1 && (
      <nav>
        <ul className="pagination justify-content-center">
          {/* Previous Button */}
          {page > 1 && (
            <li className="page-item">
              <Link
                className="page-link"
                to={getPageLink(page - 1)}
              >
                &laquo;
              </Link>
            </li>
          )}

          {/* Page Buttons */}
          {[...Array(end - start + 1)].map((_, idx) => {
            const pageNum = start + idx;
            return (
              <li
                className={`page-item ${pageNum === page ? "active" : ""}`}
                key={pageNum}
              >
                <Link
                  className="page-link"
                  to={getPageLink(pageNum)}
                >
                  {pageNum}
                </Link>
              </li>
            );
          })}

          {/* Next Button */}
          {page < pages && (
            <li className="page-item">
              <Link
                className="page-link"
                to={getPageLink(page + 1)}
              >
                &raquo;
              </Link>
            </li>
          )}
        </ul>
      </nav>
    )
  );
};

export default Pagination;
